import { useRouter } from 'next/router';
import s from './Search.module.css';
import { useState, useRef } from 'react';
import { SearchProps } from '@/types/components/search';

const Search = ({ placeholder, slug, text = '', searchPage }: SearchProps) => {
    const [searchText, setSearchText] = useState<string>(text || '');
    const [searchOpen, setSearchOpen] = useState<boolean>(searchPage || false);

    const searchFieldRef = useRef(null);

    const router = useRouter();
    const searchPageClass = searchPage
        ? 'searchBar--searchPage'
        : 'header--search';

    const open = searchOpen ? 'searchBar--open' : '';

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (searchText) {
            router.push(`${slug}?search=${searchText}`);
        }
    };

    const handleClick = () => {
        if (!searchPage) {
            setSearchOpen(!searchOpen);
            (searchFieldRef.current as HTMLInputElement | null)?.focus();
        }
    };

    return (
        <nav className={` ${s[searchPageClass]} ${s[open]}`}>
            <form
                onSubmit={handleSubmit}
                className={s.searchBar__form}
                role="search">
                <input
                    type="search"
                    ref={searchFieldRef}
                    placeholder={placeholder}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className={s.searchBar__input}
                />
                {searchOpen ? (
                    <button type="submit" aria-label="Search button">
                        <span className={`material-icons ${s.searchBar__icon}`}>
                            search
                        </span>
                    </button>
                ) : (
                    <button
                        aria-label="Search button"
                        type={'button'}
                        onClick={handleClick} // Only add onClick if it's in the Header component
                    >
                        <span className={`material-icons ${s.searchBar__icon}`}>
                            search
                        </span>
                    </button>
                )}
            </form>
        </nav>
    );
};

export default Search;
