import React, { Fragment } from 'react';
import Link from 'next/link';
import {
    WbHeader,
    WbLogo,
} from '@digital-engineering-experiences/wb-webcomponents';
import Search from '../Search';

import s from './Header.module.css';
import { useRouter } from 'next/router';
import { HeaderProps } from '@/types/components/header';
import { usePathname } from 'next/navigation';

const Header = ({
    navigation,
    logo,
    stickyHeader,
    siteName,
    translationsCurrentPage,
    search,
}: HeaderProps) => {
    // If siteName is empty, set a space to avoid the header from collapsing
    const siteNameHeader = siteName === '' ? ' ' : siteName;

    const router = useRouter();
    const pathname = usePathname();

    const isActive = (languageCode: string) => {
        return router.locale === languageCode;
    };

    return (
        <>
            <WbHeader
                skiplink={
                    <a className={s.skiplink + ' wb-btn'} href="#maincontent">
                        Skip menu
                    </a>
                }
                sticky={stickyHeader}
                logo={
                    <Link
                        href={'/'}
                        aria-label={
                            router.locale == 'nl'
                                ? 'Ga naar de homepage'
                                : 'Go to the homepage'
                        }>
                        {logo ? (
                            <img
                                src={logo.url}
                                alt="logo with link to homepage"></img>
                        ) : (
                            <WbLogo />
                        )}
                    </Link>
                }
                apptitle={siteNameHeader}>
                <>
                    {navigation && (
                        <nav>
                            <ul>
                                {navigation.map((item, index) => {
                                    return (
                                        <li key={'nav' + index}>
                                            <Link
                                                legacyBehavior
                                                href={`${item.url}`}>
                                                <a
                                                    aria-current={
                                                        pathname ===
                                                        item.url.replace(
                                                            /^\/[a-z]{2}\//,
                                                            '/'
                                                        )
                                                            ? 'true'
                                                            : undefined
                                                    }>
                                                    {item.title}
                                                </a>
                                            </Link>
                                            {item.submenu.length > 0 && (
                                                <ul>
                                                    {item.submenu.map(
                                                        (subitem, subindex) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        'sub' +
                                                                        subindex
                                                                    }>
                                                                    <Link
                                                                        legacyBehavior
                                                                        href={`${subitem.url}`}>
                                                                        <a
                                                                            aria-current={
                                                                                pathname ===
                                                                                subitem.url.replace(
                                                                                    /^\/[a-z]{2}\//,
                                                                                    '/'
                                                                                )
                                                                                    ? 'true'
                                                                                    : undefined
                                                                            }>
                                                                            {
                                                                                subitem.title
                                                                            }
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    )}

                    {search && <Search {...search} />}

                    {translationsCurrentPage.length > 1 && (
                        <nav className={s.language}>
                            <ul>
                                {translationsCurrentPage.map(
                                    (translation, index) => {
                                        return (
                                            <li key={index}>
                                                <Link
                                                    legacyBehavior
                                                    href={translation.slug}
                                                    className={`${
                                                        s.languageButton
                                                    } ${
                                                        isActive(
                                                            translation.languageCode
                                                        )
                                                            ? s.activeLanguage
                                                            : ''
                                                    }`}
                                                    locale={false}>
                                                    <a
                                                        aria-current={
                                                            isActive(
                                                                translation.languageCode
                                                            ) && true
                                                        }>
                                                        {translation.languageCode.toUpperCase()}
                                                    </a>
                                                </Link>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </nav>
                    )}
                </>
            </WbHeader>
        </>
    );
};

export default Header;
